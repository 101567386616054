<template>
  <div class="main-content">
    <b-overlay :show="showOverlay" opacity="0.60" rounded="sm" variant='white' no-wrap></b-overlay>
    <h2 class="mb-4">{{ mode === 'add' ? 'Add Vendor' : 'Edit Vendor' }}</h2>
    <!-- <div class="wrapper"> -->
    <b-row>
      <b-col md="12 mb-30">
        <b-card>
          <!-- <b-form @submit.prevent="submit" novalidate> -->
            <b-row>
              <b-form-group
                class="col-md-6 mb-3"
                label="Vendor Name"
                label-for="supplierName"
                :class="{ 'form-group--error': $v.form.supplierName.$error }"
              >
                <b-form-input
                  v-model="form.supplierName"
                  type="text"
                  required
                  placeholder="Vendor Name"
                  size="lg"
                ></b-form-input>
                <div class="error" v-if="$v.form.supplierName.$error">
                  Field is required
                </div>
              </b-form-group>

              <b-form-group
                class="col-md-6 mb-3"
                label="Sur Name"
                label-for="surName"
              >
                <b-form-input
                  v-model="form.surName"
                  type="text"
                  required
                  placeholder="Sur Name"
                  size="lg"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                class="col-md-6 mb-3"
                label="Contact Person"
                label-for="contactPerson"
              >
                <b-form-input
                  v-model="form.contactPerson"
                  type="text"
                  required
                  placeholder="Contact Person"
                  size="lg"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                class="col-md-6 mb-3"
                label="Email"
                label-for="email"
                :class="{ 'form-group--error': $v.form.supplierName.$error }"
              >
                <b-form-input
                  v-model="form.email"
                  type="email"
                  required
                  placeholder="Email"
                  size="lg"
                ></b-form-input>
                <div class="error" v-if="$v.form.email.$error">
                  Field is required
                </div>
              </b-form-group>

              <b-form-group
                class="col-md-6 mb-3"
                label="Website"
                label-for="website"
              >
                <b-form-input
                  v-model="form.website"
                  type="text"
                  required
                  placeholder="Website"
                  size="lg"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                class="col-md-6 mb-3"
                label="Mobile Number"
                label-for="mobileNo"
              >
                <b-form-input
                  v-model="form.mobileNo"
                  type="text"
                  required
                  placeholder="Mobile Number"
                  size="lg"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                class="col-md-6 mb-3"
                label="Phone Number"
                label-for="phoneNo"
              >
                <b-form-input
                  v-model="form.phoneNo"
                  type="text"
                  required
                  placeholder="Phone Number"
                  size="lg"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                class="col-md-6 mb-3"
                label="Tax Number"
                label-for="taxNo"
              >
                <b-form-input
                  v-model="form.taxNo"
                  type="text"
                  required
                  placeholder="Tax Number"
                  size="lg"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                label="Proof Type"
                label-for="proofTypeId"
                class="col-md-6"
              >
                <b-form-select
                  v-model="form.proofTypeId"
                  id="inline-form-custom-select-pref1"
                  size="lg"
                >
                  <b-form-select-option
                    :value="type.id"
                    v-for="(type, index) in proofTypes"
                    :key="index"
                    >{{ type.name }}</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>

              <b-form-group
                class="col-md-6 mb-3"
                label="Proof Number"
                label-for="proofNo"
              >
                <b-form-input
                  v-model="form.proofNo"
                  type="text"
                  required
                  placeholder="Proof Number"
                  size="lg"
                ></b-form-input>
              </b-form-group>
            </b-row>

            <b-row>
              <b-form-group
                class="col-md-6 mb-3"
                label="Description"
                label-for="description"
              >
                <b-form-textarea
                  v-model="form.description"
                  required
                  placeholder="Description"
                  size="lg"
                  rows="4"
                ></b-form-textarea>
              </b-form-group>

              <div class="col-md-6 mb-3">
                <b-form-group
                  class="mb-4"
                  label="Upload Proof"
                  label-for="proofUrl"
                >
                  <b-form-file
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    @change="uploadProof"
                  ></b-form-file>
                  <div v-if="mode === 'edit'">
                    <img class="img-thumbnail p-2 mt-3" :src="getFullImageUrl(form.proofUrl)" alt="Image preview"/>
                  </div>
                </b-form-group>

                <b-form-group
                  class="mb-3"
                  label="Upload Logo"
                  label-for="logo"
                >
                  <b-form-file
                    v-model="form.logo"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    @change="uploadLogo"
                  ></b-form-file>
                  <div v-if="mode === 'edit'">
                    <img class="img-thumbnail p-2 mt-3" :src="getFullImageUrl(form.imageUrl)" alt="Image preview"/>
                  </div>
                </b-form-group>
              </div>
            </b-row>

            <b-row>
              <b-form-group
                class="col-md-6 mb-3"
                label="Address"
                label-for="input-1"
              >
                <b-form-textarea
                  v-model="form.supplierAddress.address"
                  required
                  placeholder="Address"
                  size="lg"
                  rows="4"
                ></b-form-textarea>
                <!-- <div class="error" v-if="$v.form.supplierAddress.address">
                  Field is required
                </div> -->
              </b-form-group>

              <div class="col-md-6">
                <b-form-group
                  label="Country"
                  label-for="country"
                >
                  <b-form-select
                    v-model="form.supplierAddress.countryName"
                    id="inline-form-custom-select-pref1"
                    size="lg"
                    @change="countryChanged()"
                  >
                    <b-form-select-option
                      :value="country"
                      v-for="(country, index) in countries"
                      :key="index"
                      >{{ country.countryName }}</b-form-select-option
                    >
                  </b-form-select>
                  <!-- <div class="error" v-if="$v.form.supplierAddress.countryName">
                    Field is required
                  </div> -->
                </b-form-group>

                <b-form-group
                  class="mb-3"
                  label="City"
                  label-for="city"
                >
                  <b-form-select
                    v-model="form.supplierAddress.cityName"
                    id="inline-form-custom-select-pref1"
                    size="lg"
                    v-if="city.length > 0"
                  >
                    <b-form-select-option
                      :value="c"
                      v-for="(c, index) in city"
                      :key="index"
                      >{{ c }}</b-form-select-option
                    >
                  </b-form-select>
                  <b-form-input
                    v-model="form.supplierAddress.cityName"
                    type="text"
                    required
                    size="lg"
                    v-else
                  ></b-form-input>
                  <!-- <div class="error" v-if="$v.form.supplierAddress.cityName">
                    Field is required
                  </div> -->
                </b-form-group>
              </div>
            </b-row>

            <b-row>
              <b-form-group
                label="Billing Address"
                label-size="lg"
                label-class="font-weight-bold pt-0"
                class="col-md-12"
              >
                <b-row>
                  <b-form-group
                    class="col-md-12 mb-0 d-flex justify-content-end"
                    label-for="billing-same"
                  >
                    <b-form-checkbox
                      id="billing-same"
                      v-model="billingSame"
                      name="billing-same"
                      @change="billingSameChanged($event)"
                    >
                      Same As Address
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group
                    class="col-md-6 mb-3"
                    label="Address"
                    label-for="input-1"
                  >
                    <b-form-textarea
                      v-model="form.billingAddress.address"
                      required
                      placeholder="Address"
                      size="lg"
                      rows="4"
                    ></b-form-textarea>
                    <!-- <div class="error" v-if="$v.form.billingAddress.address">
                      Field is required
                    </div> -->
                  </b-form-group>

                  <div class="col-md-6">
                    <b-form-group
                      label="Country"
                      label-for="country"
                    >
                      <b-form-select
                        v-model="form.billingAddress.countryName"
                        id="inline-form-custom-select-pref1"
                        size="lg"
                        @change="shippingCountryChanged()"
                      >
                        <b-form-select-option
                          :value="country"
                          v-for="(country, index) in countries"
                          :key="index"
                          >{{ country.countryName }}</b-form-select-option
                        >
                      </b-form-select>
                      <!-- <div
                        class="error"
                        v-if="$v.form.billingAddress.countryName"
                      >
                        Field is required
                      </div> -->
                    </b-form-group>

                    <b-form-group
                      class="mb-3"
                      label="City"
                      label-for="city"
                    >
                      <b-form-select
                        v-model="form.billingAddress.cityName"
                        id="inline-form-custom-select-pref1"
                        size="lg"
                        v-if="shippingCity.length > 0"
                      >
                        <b-form-select-option
                          :value="c"
                          v-for="(c, index) in shippingCity"
                          :key="index"
                          >{{ c }}</b-form-select-option
                        >
                      </b-form-select>
                      <b-form-input
                        v-model="form.billingAddress.cityName"
                        type="text"
                        required
                        size="lg"
                        v-else
                      ></b-form-input>
                      <!-- <div class="error" v-if="$v.form.billingAddress.cityName">
                        Field is required
                      </div> -->
                    </b-form-group>
                  </div>
                </b-row>
              </b-form-group>
            </b-row>

            <b-row>
              <b-form-group
                label="Shipping Address"
                label-size="lg"
                label-class="font-weight-bold pt-0"
                class="col-md-12"
              >
                <b-row>
                  <b-form-group
                    class="col-md-12 mb-0 d-flex justify-content-end"
                    label-for="shipping-same"
                  >
                    <b-form-checkbox
                      id="shipping-same"
                      v-model="shippingSame"
                      name="shipping-same"
                      @change="shippingSameChanged($event)"
                    >
                      Same As Address
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group
                    class="col-md-6 mb-3"
                    label="Address"
                    label-for="input-1"
                  >
                    <b-form-textarea
                      v-model="form.shippingAddress.address"
                      required
                      placeholder="Address"
                      size="lg"
                      rows="4"
                    ></b-form-textarea>
                    <!-- <div class="error" v-if="$v.form.shippingAddress.address">
                      Field is required
                    </div> -->
                  </b-form-group>

                  <div class="col-md-6">
                    <b-form-group
                      label="Country"
                      label-for="country"
                    >
                      <b-form-select
                        v-model="form.shippingAddress.countryName"
                        id="inline-form-custom-select-pref1"
                        size="lg"
                        @change="billingCountryChanged()"
                      >
                        <b-form-select-option
                          :value="country"
                          v-for="(country, index) in countries"
                          :key="index"
                          >{{ country.countryName }}</b-form-select-option
                        >
                      </b-form-select>
                      <!-- <div
                        class="error"
                        v-if="$v.form.shippingAddress.countryName"
                      >
                        Field is required
                      </div> -->
                    </b-form-group>

                    <b-form-group
                      class="mb-3"
                      label="City"
                      label-for="city"
                    >
                      <b-form-select
                        v-model="form.shippingAddress.cityName"
                        id="inline-form-custom-select-pref1"
                        size="lg"
                        v-if="shippingCity.length > 0"
                      >
                        <b-form-select-option
                          :value="c"
                          v-for="(c, index) in shippingCity"
                          :key="index"
                          >{{ c }}</b-form-select-option
                        >
                      </b-form-select>
                      <b-form-input
                        v-model="form.shippingAddress.cityName"
                        type="text"
                        required
                        size="lg"
                        v-else
                      ></b-form-input>
                      <!-- <div
                        class="error"
                        v-if="$v.form.shippingAddress.cityName"
                      >
                        Field is required
                      </div> -->
                    </b-form-group>
                  </div>
                </b-row>
              </b-form-group>
            </b-row>

            <b-row>
              <b-col md="12" class="d-flex justify-content-end">
                <b-button
                  class="mt-3"
                  variant="primary"
                  @click="submit"
                  >Submit</b-button
                >
              </b-col>
            </b-row>
          <!-- </b-form> -->
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapGetters, mapActions } from "vuex";
import constants from "@/constants";

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Add Product",
  },
  data() {
    return {
      imageBaseUrl: constants.imageBaseUrl,
      showOverlay: false,
      shippingSame: false,
      billingSame: false,
      form: {
        id: null,
        supplierName: "",
        contactPerson: "",
        mobileNo: "",
        phoneNo: "",
        website: "",
        description: "",
        email: "",
        url: "",
        isVarified: true,
        isUnsubscribe: false,
        supplierProfile: "",
        supplierAddress: {
          id: "",
          address: "",
          countryName: "",
          cityName: "",
        },
        billingAddress: {
          id: "",
          address: "",
          countryName: "",
          cityName: "",
        },
        shippingAddress: {
          id: "",
          address: "",
          countryName: "",
          cityName: "",
        },
        surName: "",
        taxNo: "",
        proofNo: "",
        proofTypeId: "",
        logo: "",
        isImageUpload: false,
        proofUrl: "",
        isDocUpload: false,
      },
      proofOptions: [],
      countryOptions: [],
      mode: "add"
    };
  },
  validations: {
    form: {
      supplierName: {
        required,
      },
      email: {
        required,
      },
      // supplierAddress: {
      //   address: {
      //     required,
      //   },
      //   countryName: {
      //     required,
      //   },
      //   cityName: {
      //     required,
      //   },
      // },
      // billingAddress: {
      //   address: {
      //     required,
      //   },
      //   countryName: {
      //     required,
      //   },
      //   cityName: {
      //     required,
      //   },
      // },
      // shippingAddress: {
      //   address: {
      //     required,
      //   },
      //   countryName: {
      //     required,
      //   },
      //   cityName: {
      //     required,
      //   },
      // },
    },
  },
  computed: {
    ...mapGetters(["proofTypes", "countries", "city", "shippingCity", "billingCity"]),
    vendorId() {
      return this.$route.params.id;
    }
  },
  methods: {
    ...mapActions(["getVendor", "getProofTypes", "getCountries", "getCity", "getShippingCity", "getBillingCity", "addVendor", "updateVendor"]),
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$bvToast.toast('Please fill required fields', {
            title: 'Error',
            variant: "danger",
            autoHideDelay: 5000,
          })
      } else {
        this.showOverlay = true;
        this.form.billingAddress.countryName = this.form.billingAddress.countryName.countryName
        this.form.shippingAddress.countryName = this.form.shippingAddress.countryName.countryName
        this.form.supplierAddress.countryName = this.form.supplierAddress.countryName.countryName
        if(this.form.logo) {
          this.form.isImageUpload = true
        } else {
          this.form.isImageUpload = false
        }

        if(this.form.proofUrl) {
          this.form.isDocUpload = true
        } else {
          this.form.isDocUpload = false
        }

        if(this.vendorId) {
          this.updateVendor(this.form).then(c => {
            this.showOverlay = false;
            this.$bvToast.toast("Vendor updated successfully", {
              title: "Success",
              variant: "success",
              autoHideDelay: 5000,
            })
            this.$router.push('/vendor')
          })
          .catch(err => {
            this.showOverlay = false;
            this.$bvToast.toast(err, {
              title: "Error",
              variant: "danger",
              autoHideDelay: 5000,
            })
          })
        } else {
          this.addVendor(this.form).then(c => {
            this.showOverlay = false;
            this.$bvToast.toast("Vendor added successfully", {
              title: "Success",
              variant: "success",
              autoHideDelay: 5000,
            })
            this.$router.push('/vendor')
          })
          .catch(err => {
            this.showOverlay = false;
            this.$bvToast.toast(err, {
              title: "Error",
              variant: "danger",
              autoHideDelay: 5000,
            })
          })
        }
      }
    },
    countryChanged() {
      this.getCity(this.form.supplierAddress.countryName.countryName);
    },
    shippingCountryChanged() {
      this.getShippingCity(this.form.shippingAddress.countryName.countryName);
    },
    billingCountryChanged() {
      this.getBillingCity(this.form.billingAddress.countryName.countryName);
    },
    billingSameChanged(event) {
      if(event) {
        this.form.billingAddress.address = this.form.supplierAddress.address
        this.form.billingAddress.countryName = this.form.supplierAddress.countryName
        this.form.billingAddress.cityName = this.form.supplierAddress.cityName
      } else {
        this.form.billingAddress.address = ""
        this.form.billingAddress.countryName = ""
        this.form.billingAddress.cityName = ""
      }
    },
    shippingSameChanged(event) {
      if(event) {
        this.form.shippingAddress.address = this.form.supplierAddress.address
        this.form.shippingAddress.countryName = this.form.supplierAddress.countryName
        this.form.shippingAddress.cityName = this.form.supplierAddress.cityName
      } else {
        this.form.shippingAddress.address = ""
        this.form.shippingAddress.countryName = ""
        this.form.shippingAddress.cityName = ""
      }
    },
    uploadProof() {
      const vm = this;
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        vm.form.proofUrl = reader.result
      };
    },
    uploadLogo() {
      const vm = this;
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        vm.form.logo = reader.result
      };
    },
    getFullImageUrl(path) {
      if (path) {
        // Replace backslashes with forward slashes and add "Thumbnail" after "ProductImages"
        path = path.replace(/\\/g, '/').replace('ProductImages/', 'ProductImages/Thumbnail/');
        // Ensure there's no duplicate slash
        if (this.imageBaseUrl.endsWith('/') && path.startsWith('/')) {
          path = path.slice(1);
        } else if (!this.imageBaseUrl.endsWith('/') && !path.startsWith('/')) {
          path = '/' + path;
        }
        return this.imageBaseUrl + path;
      }
      return '';
    }
  },
  async mounted() {
    this.getProofTypes();
    this.getCountries();

    if (this.vendorId) {
      this.mode = "edit"
      const currentVendor = await this.getVendor(this.vendorId);
      this.form = {...this.form, ...currentVendor}
    }
  },
};
</script>

<style scoped>
.form-group label {
  font-size: 14px !important;
}
.form-control {
  background: #ffffff;
}
</style>
